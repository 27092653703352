import {
  DISCLOSURE_REGEX,
  COMP_AI_REGEX,
} from "../../utils/Constants/systemSetting";
import {
  GET_ALL_COMPANIES_SAGA,
  GET_ALL_MARKET_CAP,
  GET_EXECUTIVES_SAGA,
  GET_MARKET_CAP_SAGA,
  GET_PARAGRAPHS_8K502_SAGA,
  GET_MEDIAN_BY_YEAR_SAGA,
  DISCLOSURE_SEARCH,
  CLEAR_DISCLOSURE_RESULTS,
  COMP_AI_SEARCH,
  CLEAR_COMP_AI_RESULTS,
  COMP_AI_SEARCH_AVAILABILITY,
  RUN_HORSE_RACE,
  RUN_OPTIMISTIC_HORSE_RACE,
  GET_TSR_VALUES_SAGA,
  GET_BOARD_PAY_SAGA,
  GET_BOARD_PAY_PROFILES_SAGA,
  GET_ALL_BOARD_PAY_SAGA,
  GET_MONTHLY_RETURNS_SAGA,
  GET_INDEX_MONTHLY_RETURNS_SAGA,
  GET_MONTHLY_MARKETCAPS_SAGA,
  GET_WEEKLY_RETURNS_SAGA,
  GET_AWARDS_SAGA,
  GET_RISK_FACTORS_SAGA,
  GET_DAILY_PRICES_SAGA,
  COMP_AI_RESULT_RATING,
  CLEAR_HORSE_RACE_RESULTS,
} from "../constants/CompanyConstant";

export const getAllCompanies = () => ({
  type: GET_ALL_COMPANIES_SAGA,
});

export const getAllMarketCap = () => ({
  type: GET_ALL_MARKET_CAP,
});

export const getAllExecutives = () => ({
  type: GET_EXECUTIVES_SAGA,
});

export const getMarketCap = (ticker) => ({
  type: GET_MARKET_CAP_SAGA,
  ticker,
});

export const getParagraphsFrom8k502 = (ticker) => ({
  type: GET_PARAGRAPHS_8K502_SAGA,
  ticker,
});

export const getMonthlyReturns = (tickers, token) => ({
  type: GET_MONTHLY_RETURNS_SAGA,
  tickers,
  token,
});
export const getMonthlyMarketcaps = (tickers, token) => ({
  type: GET_MONTHLY_MARKETCAPS_SAGA,
  tickers,
  token,
});
export const getWeeklyReturns = (tickers, token) => ({
  type: GET_WEEKLY_RETURNS_SAGA,
  tickers,
  token,
});

export const getDailyPrices = (tickers, token) => ({
  type: GET_DAILY_PRICES_SAGA,
  tickers,
  token,
});

export const getAwards = (tickers, token) => ({
  type: GET_AWARDS_SAGA,
  tickers,
  token,
});

export const getRiskFactors = (tickers, token) => ({
  type: GET_RISK_FACTORS_SAGA,
  tickers,
  token,
});

export const getBoardPay = (ticker, token) => ({
  type: GET_BOARD_PAY_SAGA,
  ticker,
  token,
});

export const getBoardPayProfiles = (ticker, token) => ({
  type: GET_BOARD_PAY_PROFILES_SAGA,
  ticker,
  token,
});

export const getAllBoardPay = (token) => ({
  type: GET_ALL_BOARD_PAY_SAGA,
  token,
});

export const getIndexMonthlyReturns = (token) => ({
  type: GET_INDEX_MONTHLY_RETURNS_SAGA,
  token,
});

export const getMedianByYear = (year) => ({
  type: GET_MEDIAN_BY_YEAR_SAGA,
  year,
});

export const disclosureSearch = (search) => {
  if (search?.searchQuery) {
    search.searchQuery = search.searchQuery
      ?.replace(DISCLOSURE_REGEX, "")
      .slice(0, 50);
  }
  return {
    type: DISCLOSURE_SEARCH,
    search,
  };
};
export const clearDisclosureResults = () => {
  return {
    type: CLEAR_DISCLOSURE_RESULTS,
  };
};

export const compAISearch = (search) => {
  if (search?.searchQuery) {
    search.searchQuery = search.searchQuery
      ?.replace(COMP_AI_REGEX, "")
      .slice(0, 500);
  }
  return {
    type: COMP_AI_SEARCH,
    search,
  };
};

export const clearCompAIResults = () => {
  return {
    type: CLEAR_COMP_AI_RESULTS,
  };
};

export const compAISearchAvailability = (search) => {
  return {
    type: COMP_AI_SEARCH_AVAILABILITY,
    search,
  };
};

export const compAIResultRating = (ratingDetails) => {
  return {
    type: COMP_AI_RESULT_RATING,
    ratingDetails,
  };
};

export const runHorseRace = (search) => {
  return {
    type: RUN_HORSE_RACE,
    search,
  };
};

export const runOptimisticHorseRace = (search) => {
  return {
    type: RUN_OPTIMISTIC_HORSE_RACE,
    search,
  };
};

export const clearHorseRaceResults = () => {
  return {
    type: CLEAR_HORSE_RACE_RESULTS,
  };
};

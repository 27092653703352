import React, { useEffect, useMemo, useState, useCallback } from "react";
import "../css/overview.css";
import { v4 as uuidv4 } from "uuid";
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  BORDER_WIDTH,
  ETRADE_URL,
  isFirstYearOfComp,
  renderNetIncome,
  toDollar,
  dataKeyMap,
  formatMeasure,
  payRatioCalculatedMessage,
  emdash,
  MIN_YEAR,
} from "../utils/Constants/systemSetting";
import BarGraphOverview from "../components/Graphs/BarGraphOverview";
import LineGraph from "../components/Graphs/LineGraph";
import dataFilter from "../utils/DataFilter/dataFilter";
import { useSelector } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import { Popover, Checkbox, Button, Select, Radio } from "antd";
import { Link, useLocation } from "react-router-dom";
import { getParagraphsFrom8k502 } from "../redux/actions/CompanyAction";
import { useDispatch } from "react-redux";
import slugifyName from "../utils/slugifyName";
import NotFound from "./notFound";
import { getExecPosition } from "../utils/ExecutiveData/getExecPosition";
import DisclosureSearchBar from "../components/DisclosureSearchBar.js";
import CompanyCalculator from "../components/CompanyCalculator.js";
import BoardPay from "../components/BoardPay";
import PeerGroupAnalysis from "../components/PeerGroupAnalysis.js";
import IncentiveStructure from "../components/IncentiveStructure.js";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";
import ExportPDFButton from "../components/ExportPDFButton.js";
import OptionsCalculators from "../components/OptionsCalculators.js";
import CompAI from "../components/CompAI.js";
import RTSRCalculators from "../components/RTSRCalculators.js";
import RiskModule from "../components/RiskModule.js";

const pageModeOptions = [
  "Company Overview",
  "Company Calculators",
  "Peer Group Analysis",
  "Board Pay",
  "Options Calculators",
  "rTSR Calculators",
  "Incentive Structure",
  "Risk",
  "Comp AI™",
];

// These are used to navigate to the page directly, add these as pagmodeoptions are added
// (overview is default view, so it is not needed here)
const hashMap = {
  calculators: "Company Calculators",
  peers: "Peer Group Analysis",
  board: "Board Pay",
  options: "Options Calculators",
  rtsr: "rTSR Calculators",
  incentives: "Incentive Structure",
  risk: "Risk",
  "comp-ai": "Comp AI™",
};

const reverseHashMap = {};
Object.entries(hashMap).forEach(([key, val]) => {
  reverseHashMap[val] = key;
});

export default function CompanyOverview() {
  const version = localStorage.getItem("version") || "1.0.0";

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("2020");
  // const { info, executives, marketCap } = useSelector(
  //   (state) => state.CompanyReducer
  // );
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);

  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const peerGroupAccess = useMemo(() => {
    if (subscription?.plan?.metadata?.peerGroupAccess === "true") {
      return true;
    }
    return false;
  }, [subscription]);

  const compAIaccess = useMemo(() => {
    if (user?.compAIaccess) {
      return true;
    }
    return false;
  }, [user]);

  const [companyNotFound, setCompanyNotFound] = useState(false);
  const [PDFprocessing, setPDFprocessing] = useState(false);

  const [customUserPeerGroups, setCustomUserPeerGroups] = useState(
    user?.customPeerGroups || {}
  );

  useMemo(() => {
    if (user?.customPeerGroups && Object.keys(user?.customPeerGroups).length) {
      setCustomUserPeerGroups(user?.customPeerGroups);
    }
  }, [user?.customPeerGroups]);

  const { companies, marketCap: marketCaps } = useSelector(
    (state) => state.CompaniesReducer
  );
  const { paragraphsFrom8k502 } = useSelector((state) => state.CompanyReducer);
  const { csuite: allExecutives } = useSelector(
    (state) => state.ExecutivesReducer
  );
  const [info, setInfo] = useState();
  const [executives, setExecutives] = useState([]);
  const [analystCohortExecutives, setAnalystCohortExecutives] = useState([]);
  const [proxyCohortExecutives, setProxyCohortExecutives] = useState([]);
  const [compensationCohortExecutives, setCompensationCohortExecutives] =
    useState([]);
  const [performanceCohortExecutives, setPerformanceCohortExecutives] =
    useState([]);
  const [compAIProxyCohortExecutives, setCompAIProxyCohortExecutives] =
    useState([]);
  const [
    compAIPerformanceCohortExecutives,
    setCompAIPerformanceCohortExecutives,
  ] = useState([]);
  const [
    compAICompensationCohortExecutives,
    setCompAICompensationCohortExecutives,
  ] = useState([]);
  const [customCohortExecutives, setCustomCohortExecutives] = useState([]);
  const [CSVExportOptions, setCSVExportOptions] = useState([
    { value: "company", label: "Company" },
  ]);
  const [CSVExportOptionSelected, setCSVExportOptionSelected] =
    useState("company");
  const [marketCap, setMarketCap] = useState();
  const [includesSARaward, setIncludesSARaward] = useState(false);
  const [includesRSUs, setIncludesRSUs] = useState(false);
  const [includesPSUs, setIncludesPSUs] = useState(false);
  const [yearsWithData, setYearsWithData] = useState([]);
  const [dataPeekLinks, setDataPeekLinks] = useState(<div></div>);
  const [payRatioIsCalculated, setPayRatioIsCalculated] = useState(false);
  const [pageModeSelected, setPageModeSelected] = useState(
    "Company Calculators"
  );

  const [showRankWithinCompany, setShowRankWithinCompany] = useState(false);
  const [
    tableContainsRowDoesNotEqualTotal,
    setTableContainsRowDoesNotEqualTotal,
  ] = useState(false);

  const location = useLocation();

  // TODO: Separate CSV export Component //
  const getCompensationTableKeysAndYears = (execs) => {
    const execTableKeys = [];
    const yearsPossible = [];
    for (let yr = new Date().getFullYear(); yr >= MIN_YEAR; yr--) {
      yearsPossible.push(yr);
    }
    const yearsAvailable = [];
    for (const exec of execs) {
      for (const yearPossible of yearsPossible) {
        if (!exec?.compensation?.[yearPossible]) continue;
        if (!yearsAvailable.includes(yearPossible)) {
          yearsAvailable.push(yearPossible);
        }

        for (const key of Object.keys(exec.compensation[yearPossible])) {
          if (
            !execTableKeys.includes(key) &&
            key !== "rank" &&
            key !== "undefined" &&
            key !== "originalTotal" &&
            key !== "manuallyEdited" &&
            key !== "sumOfCompensationComponents" &&
            exec.compensation[yearPossible][key] > 0
          ) {
            execTableKeys.push(key);
          }
        }
      }
    }
    execTableKeys.sort((a, b) => dataKeyMap[a].order - dataKeyMap[b].order);
    return [execTableKeys, yearsAvailable];
  };

  const formatExecutiveDataForExport = (
    executivesData,
    includeCompanyInfo = false
  ) => {
    const [compTableKeys, yearsAvailable] =
      getCompensationTableKeysAndYears(executivesData);
    const allData = [];
    for (const exec of executivesData) {
      for (const yearAvailable of yearsAvailable) {
        let sumOfCompensationComponents = 0;
        const compDataForYear = {};
        for (const compType of compTableKeys) {
          const compensationForType =
            exec?.compensation?.[yearAvailable]?.[compType];
          if (compType !== "total") {
            sumOfCompensationComponents +=
              exec?.compensation?.[yearAvailable]?.[compType] || 0;
          }
          compDataForYear[dataKeyMap[compType].title] =
            !compensationForType && compensationForType !== 0
              ? ""
              : compensationForType === emdash
              ? "0"
              : compensationForType;
        }
        if (Object.values(compDataForYear).every((val) => val === "")) {
          continue;
        }
        const execPosition = getExecPosition(exec, yearAvailable);
        //exec.position?.[yearAvailable]
        const newExecutiveData = {
          Name: `"${exec.name}"`,
          "Principal Position(s)": execPosition
            ? `"${execPosition?.join(", ")}"`
            : "",
          Year: yearAvailable,
          ...compDataForYear,
        };
        //Check if total matches sum
        if (
          newExecutiveData["Total Compensation"] &&
          Math.abs(
            parseInt(
              newExecutiveData["Total Compensation"] -
                sumOfCompensationComponents
            )
          ) > 2
        ) {
          newExecutiveData["Sum of Compensation Components"] = parseInt(
            sumOfCompensationComponents
          );
          newExecutiveData["Difference (Reported Total - Sum of Components)"] =
            parseInt(
              newExecutiveData["Total Compensation"] -
                sumOfCompensationComponents
            );
          // ensure key exists for first entry for headers:
          if (
            allData.length &&
            !allData[0]?.["Sum of Compensation Components"]
          ) {
            allData[0]["Sum of Compensation Components"] = "";
            allData[0]["Difference (Reported Total - Sum of Components)"] = "";
          }
        }
        if (includeCompanyInfo) {
          const execDataWithCompanyInfo = {
            Ticker: exec.Ticker,
            Company: `"${exec?.company}"`,
            ...newExecutiveData,
          };
          allData.push(execDataWithCompanyInfo);
        } else {
          allData.push(newExecutiveData);
        }
      }
    }
    return allData;
  };
  // End of CSV export //

  // console.log(info);

  const isCustomPeerGroup = useCallback(
    (peerGroupKey) => {
      if (
        Object.keys(customUserPeerGroups).length &&
        Object.keys(customUserPeerGroups).some((key) => key === peerGroupKey)
      ) {
        return true;
      }
      return false;
    },
    [customUserPeerGroups]
  );

  useEffect(() => {
    const id = location.pathname
      .split("/")
      [location.pathname.split("/").length - 1]?.toUpperCase();

    dispatch(getParagraphsFrom8k502(id));

    if (info?.Ticker !== id && Object.keys(companies)?.length <= 100) {
      setLoading(true);
    }

    if (!companies?.[id] && Object.keys(companies)?.length > 100) {
      setLoading(false);
      setCompanyNotFound(true);
    } else {
      setCompanyNotFound(false);
    }

    // Navigate to correct subpage for Tier 2 users
    const hash = window.location.hash.substring(1);
    if (hashMap?.[hash]) {
      setPageModeSelected(hashMap?.[hash]);
    } else {
      setPageModeSelected("Company Overview");
    }

    setInfo(companies[id]);
    setTableContainsRowDoesNotEqualTotal(false);

    for (const company of marketCaps) {
      if (company.Ticker === id) {
        setMarketCap(company?.marketCap);
        break;
      }
    }

    const executiveData = [];
    for (const executive of allExecutives) {
      if (executive.Ticker === id) {
        executiveData.push(executive);
      }
    }

    if (info?.AnalystCohort?.length) {
      setAnalystCohortExecutives(
        allExecutives.filter((exec) =>
          info.AnalystCohort.some((ticker) => ticker === exec.Ticker)
        )
      );
    } else {
      setAnalystCohortExecutives([]);
    }

    if (info?.ProxyCohort?.length) {
      setProxyCohortExecutives(
        allExecutives.filter((exec) =>
          info.ProxyCohort.some((ticker) => ticker === exec.Ticker)
        )
      );
    } else {
      setProxyCohortExecutives([]);
    }

    if (info?.ProxyCohortCompensation?.length) {
      setCompensationCohortExecutives(
        allExecutives.filter((exec) =>
          info.ProxyCohortCompensation.some((ticker) => ticker === exec.Ticker)
        )
      );
    } else {
      setCompensationCohortExecutives([]);
    }

    if (info?.ProxyCohortPerformance?.length) {
      setPerformanceCohortExecutives(
        allExecutives.filter((exec) =>
          info.ProxyCohortPerformance.some((ticker) => ticker === exec.Ticker)
        )
      );
    } else {
      setPerformanceCohortExecutives([]);
    }

    if (info?.CompAIProxyCohort?.length) {
      setCompAIProxyCohortExecutives(
        allExecutives.filter((exec) =>
          info.CompAIProxyCohort.some((ticker) => ticker === exec.Ticker)
        )
      );
    } else {
      setCompAIProxyCohortExecutives([]);
    }

    if (info?.CompAIProxyCohortPerformance?.length) {
      setCompAIPerformanceCohortExecutives(
        allExecutives.filter((exec) =>
          info.CompAIProxyCohortPerformance.some(
            (ticker) => ticker === exec.Ticker
          )
        )
      );
    } else {
      setCompAIPerformanceCohortExecutives([]);
    }

    if (info?.CompAIProxyCohortCompensation?.length) {
      setCompAICompensationCohortExecutives(
        allExecutives.filter((exec) =>
          info.CompAIProxyCohortCompensation.some(
            (ticker) => ticker === exec.Ticker
          )
        )
      );
    } else {
      setCompAICompensationCohortExecutives([]);
    }

    // if (
    //   isCustomPeerGroup(CSVExportOptionSelected) &&
    //   CSVExportOptions.find((item) => item.label === CSVExportOptionSelected)
    //     .tickers
    // ) {
    //   const customTickersNeeded = console.log(customTickersNeeded);
    //   if (customTickersNeeded.length) {
    //     setCustomCohortExecutives(
    //       allExecutives.filter((exec) =>
    //         CSVExportOptions.find(
    //           (item) => item.label === CSVExportOptionSelected
    //         ).tickers.includes(exec.Ticker)
    //       )
    //     );
    //   } else {
    //     setCustomCohortExecutives([]);
    //   }
    // }

    // console.log("8k 502s", paragraphsFrom8k502);
    // console.log("companies", companies);
    // console.log("execs", allExecutives);
    // console.log("marketCaps", marketCaps);

    setExecutives(executiveData);
    if (
      allExecutives.length &&
      Object.keys(companies).length > 7 &&
      info?.Ticker === id
    ) {
      setLoading(false);
    }

    setIncludesSARaward(false);
    setIncludesRSUs(false);
    setIncludesPSUs(false);

    setCSVExportOptionSelected("company");
  }, [info, allExecutives, companies, marketCaps, location.pathname, dispatch]);

  useEffect(() => {
    const CSVoptions = [];
    CSVoptions.push({
      value: "company",
      label: "Company",
      disabled: executives.length === 0,
    });
    CSVoptions.push({
      value: "analyst",
      label: "Analyst Cohort",
      disabled: analystCohortExecutives.length === 0,
    });
    if (info?.ProxyCohort) {
      CSVoptions.push({
        value: "proxy",
        label: "Proxy Cohort",
        disabled: proxyCohortExecutives.length === 0,
      });
    }

    if (info?.ProxyCohortCompensation) {
      CSVoptions.push({
        value: "proxyCompensation",
        label: "Proxy Cohort (Compensation)",
        disabled: compensationCohortExecutives.length === 0,
      });
    }
    if (info?.ProxyCohortPerformance) {
      CSVoptions.push({
        value: "proxyPerformance",
        label: "Proxy Cohort (Performance)",
        disabled: performanceCohortExecutives.length === 0,
      });
    }

    if (info?.CompAIProxyCohort) {
      CSVoptions.push({
        value: "compAIproxy",
        label: "Comp AI Proxy Cohort",
        disabled: compAIProxyCohortExecutives.length === 0,
      });
    }

    if (info?.CompAIProxyCohortCompensation) {
      CSVoptions.push({
        value: "compAIproxyCompensation",
        label: "Comp AI Proxy Cohort (Compensation)",
        disabled: compAICompensationCohortExecutives.length === 0,
      });
    }
    if (info?.CompAIProxyCohortPerformance) {
      CSVoptions.push({
        value: "compAIproxyPerformance",
        label: "Comp AI Proxy Cohort (Performance)",
        disabled: compAIPerformanceCohortExecutives.length === 0,
      });
    }

    if (customUserPeerGroups && Object.keys(customUserPeerGroups).length) {
      for (const [customPeerGroupKey, customPeerGroupValue] of Object.entries(
        customUserPeerGroups
      )) {
        CSVoptions.push({
          value: customPeerGroupKey,
          label: customPeerGroupKey,
          tickers: customPeerGroupValue,
        });
      }
    }

    setCSVExportOptions(CSVoptions);
  }, [
    info,
    executives,
    analystCohortExecutives,
    proxyCohortExecutives,
    compensationCohortExecutives,
    performanceCohortExecutives,
    customUserPeerGroups,
    compAIProxyCohortExecutives,
    compAIPerformanceCohortExecutives,
    compAICompensationCohortExecutives,
  ]);

  const getDatapoint = useCallback(
    (datapoint) => {
      /* Allows for getting most recent year */
      if (
        info?.[datapoint]?.[year?.toString()]?.value ||
        info?.[datapoint]?.[year?.toString()]?.value === 0 // revenue can be 0
      ) {
        return {
          year: year,
          value: info?.[datapoint]?.[year?.toString()]?.value,
          calculated: info?.[datapoint]?.[year?.toString()]?.calculated
            ? true
            : false,
        };
      }
      // }
      return { year: "", value: "N/A" };
    },
    [info, year]
  );

  const getMeasure = useCallback(
    (measureName) => {
      /* Allows for getting most recent year */
      if (
        info?.Measures?.[measureName]?.[year?.toString()] ||
        info?.Measures?.[measureName]?.[year?.toString()] === 0 // revenue can be 0
      ) {
        return {
          year: year,
          value: info?.Measures?.[measureName]?.[year?.toString()],
        };
      }
      // }
      return { year: "", value: "N/A" };
    },
    [info, year]
  );

  //TODO: remove if no issues
  // const data = useMemo(() => {
  //   const titles = executives?.map((exe) => exe.name) || [];
  //   const labels = [...titles];
  //   // const yearOfNetIncome = info?.netIncomeData?.find(
  //   //   (item) => item.year === 2021 || item.year === 2020
  //   // );
  //   // const netIncome = yearOfNetIncome ? yearOfNetIncome.netIncome : null;
  //   const totalCompensation =
  //     executives?.map((exe) => {
  //       if (exe?.compensation) {
  //         for (const [key, value] of Object.entries(exe?.compensation)) {
  //           if (key == "2021" || key == "2020") {
  //             if (key == "2021") {
  //               setYear("2021");
  //             } else {
  //               setYear("2020");
  //             }
  //             return (
  //               (value?.salary || 0) +
  //               (value?.bonus || 0) +
  //               (value?.stockAward || 0) +
  //               (value?.SARaward || 0) +
  //               (value?.optionAward || 0) +
  //               (value?.nonEquity || 0) +
  //               (value?.pension || 0) +
  //               (value?.other || 0)
  //             );
  //           }
  //         }
  //       }
  //     }) || [];
  //   const datasets = [
  //     {
  //       data: [...totalCompensation],
  //       backgroundColor: BACKGROUND_COLOR,
  //       borderColor: BORDER_COLOR,
  //       borderWidth: BORDER_WIDTH,
  //       barThickness: 50,
  //     },
  //   ];
  //   return {
  //     labels,
  //     datasets,
  //   };
  // }, [executives]);

  const [positions, setPositions] = useState([...new Set()]);

  useEffect(() => {
    const getClosestYear = (executives, year) => {
      const yearsAvailable = [];
      executives?.forEach((exe, index) => {
        for (const [key, value] of Object.entries(exe?.["position"])) {
          if (value?.length && !yearsAvailable.includes(key)) {
            yearsAvailable.push(key);
          }
        }
      });
      // Can return years if only one option or if a year available === current query
      if (yearsAvailable.length === 1) return yearsAvailable[0];
      if (yearsAvailable.includes(year)) return year;

      // Otherwise, find the most relevent year:
      let currentBest = {};
      for (const dataYear of yearsAvailable) {
        let score = dataYear - year;
        if (
          !currentBest?.score ||
          (score > currentBest?.score && score <= 1) ||
          (score < currentBest?.score && score >= 1)
        ) {
          currentBest = { score, year: dataYear };
        }
      }
      return currentBest?.year;
    };
    let tempPositions = [];
    let closestYear = getClosestYear(executives, year);
    executives?.forEach((exe, index) => {
      let posFound = false;

      for (const [key, value] of Object.entries(exe?.["position"])) {
        if (key === closestYear) {
          posFound = true;
          tempPositions.push(value);
        }
      }
      if (!posFound) tempPositions.push(["N/A"]);

      if (index == executives.length - 1) {
        setPositions([...new Set(tempPositions)]);
      }
    });
  }, [year, executives]);

  const [lineGroup, setLineGroup] = useState({});
  useMemo(() => {
    const yearsArray = () => {
      const years = [];
      for (let year = new Date().getFullYear(); year >= MIN_YEAR; year--) {
        years.push(year);
      }
      return years;
    };
    const years = yearsArray();
    const newLineGroup = {};
    // Needed to put data in same format as homepage line graph
    for (let y in years) {
      const newData = new dataFilter(executives, year, companies);
      newLineGroup[years[y]] = newData.data;
    }
    setLineGroup(newLineGroup);
  }, [year, executives, companies]);

  const renderCompensation = useMemo(() => {
    return executives
      ?.sort((a, b) => {
        const aKeys = Object.keys(a?.compensation);
        const bKeys = Object.keys(b?.compensation);
        let sortVal = Math.max(...bKeys) - Math.max(...aKeys);
        if (sortVal === 0) {
          sortVal = bKeys.length - aKeys.length;
        }
        return sortVal;
      })
      ?.map((exe, exeIndex) => {
        // console.log(exe);
        const row = exe?.compensation
          ? Object.entries(exe?.compensation).length
          : 1;
        // if (exe?.compensation) {
        return Object.entries(exe?.compensation)
          .slice(0)
          .reverse()
          .map((item, index) => {
            const sumTotal =
              (item[1].salary || 0) +
              (item[1].bonus || 0) +
              (item[1].stockAward || 0) +
              (item[1].RSUs || 0) +
              (item[1].PSUs || 0) +
              (item[1].optionAward || 0) +
              (item[1]?.SARaward || 0) +
              (item[1].nonEquity || 0) +
              (item[1].pension || 0) +
              (item[1].other || 0);
            if (!includesSARaward && item[1]?.SARaward > 0) {
              setIncludesSARaward(true);
            }
            if (!includesRSUs && item[1]?.RSUs > 0) {
              setIncludesRSUs(true);
            }
            if (!includesPSUs && item[1]?.PSUs > 0) {
              setIncludesPSUs(true);
            }
            let rowDoesNotEqualTotal = false;
            if (
              sumTotal &&
              !isNaN(parseInt(item[1]?.["total"])) &&
              (sumTotal + 2 <= parseInt(item[1]?.["total"]) ||
                sumTotal - 2 >= parseInt(item[1]?.["total"]))
            ) {
              rowDoesNotEqualTotal = true;
              setTableContainsRowDoesNotEqualTotal(true);
            }
            return (
              <tr
                key={index}
                style={{
                  borderColor: PDFprocessing ? "rgb(0,0,0,0.01)" : "",
                }}
              >
                {index == 0 && (
                  <td
                    className="executive"
                    rowSpan={row}
                    style={{ verticalAlign: "middle" }}
                  >
                    <Link
                      to={`/company/${info?.Ticker}/${slugifyName(exe?.name)}`}
                      style={{ color: "black" }}
                    >
                      <p className="executive-name">
                        {isFirstYearOfComp(exe, year)
                          ? exe?.name + "*"
                          : exe?.name}
                      </p>
                      <div className="title">
                        <>
                          {exe?.compensation?.[year] ? (
                            positions[exeIndex]?.map((pos, index) => (
                              <p key={index}>{pos}</p>
                            ))
                          ) : (
                            <p key={index}>No position in {year}</p>
                          )}
                        </>
                        {showRankWithinCompany &&
                          exe?.compensation?.[year]?.rank && (
                            <p key={index}>
                              Rank: {exe?.compensation?.[year]?.rank}
                            </p>
                          )}
                      </div>
                    </Link>
                  </td>
                )}

                <td
                  className="compensation"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {item[0]}
                </td>
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.salary)) || emdash}
                </td>
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.bonus)) || emdash}
                </td>
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.["stockAward"])) || emdash}
                </td>
                {includesRSUs && (
                  <td
                    className="compensation d-none d-md-table-cell"
                    style={{ fontWeight: item[0] === year ? "bold" : "" }}
                  >
                    {toDollar(parseInt(item[1]?.["RSUs"])) || emdash}
                  </td>
                )}
                {includesPSUs && (
                  <td
                    className="compensation d-none d-md-table-cell"
                    style={{ fontWeight: item[0] === year ? "bold" : "" }}
                  >
                    {toDollar(parseInt(item[1]?.["PSUs"])) || emdash}
                  </td>
                )}
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.["optionAward"])) || emdash}
                </td>
                {includesSARaward && (
                  <td
                    className="compensation d-none d-md-table-cell"
                    style={{ fontWeight: item[0] === year ? "bold" : "" }}
                  >
                    {toDollar(parseInt(item[1]?.["SARaward"])) || emdash}
                  </td>
                )}
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.["nonEquity"])) || emdash}
                </td>
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.["pension"])) || emdash}
                </td>
                <td
                  className="compensation d-none d-md-table-cell"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {toDollar(parseInt(item[1]?.["other"])) || emdash}
                </td>
                <td
                  className="compensation"
                  style={{ fontWeight: item[0] === year ? "bold" : "" }}
                >
                  {/* {total > 0 ? toDollar(parseInt(total)) : emdash} /Old method adds up numbers*/}
                  {toDollar(parseInt(item[1]?.["total"]))
                    ? toDollar(parseInt(item[1]?.["total"])) +
                      (rowDoesNotEqualTotal ? "**" : "")
                    : emdash}
                </td>
                {tableContainsRowDoesNotEqualTotal && (
                  <td
                    className="compensation"
                    style={{ fontWeight: item[0] === year ? "bold" : "" }}
                  >
                    {/* {total > 0 ? toDollar(parseInt(total)) : emdash} /Old method adds up numbers*/}
                    {rowDoesNotEqualTotal &&
                    toDollar(parseInt(item[1]?.["total"]))
                      ? toDollar(sumTotal)
                      : emdash}
                  </td>
                )}
                {tableContainsRowDoesNotEqualTotal && (
                  <td
                    className="compensation"
                    style={{
                      fontWeight: item[0] === year ? "bold" : "",
                      color:
                        parseInt(item[1]?.["total"]) - parseInt(sumTotal) >= 0
                          ? "green"
                          : "red",
                    }}
                  >
                    {/* {total > 0 ? toDollar(parseInt(total)) : emdash} /Old method adds up numbers*/}
                    {rowDoesNotEqualTotal &&
                    toDollar(parseInt(item[1]?.["total"]))
                      ? parseInt(item[1]?.["total"]) - parseInt(sumTotal) < 0
                        ? `(${toDollar(
                            Math.abs(parseInt(item[1]?.["total"]) - sumTotal)
                          )})`
                        : toDollar(
                            Math.abs(parseInt(item[1]?.["total"]) - sumTotal)
                          )
                      : emdash}
                  </td>
                )}
              </tr>
            );
          });
        // }
      });
  }, [
    executives,
    includesPSUs,
    includesRSUs,
    includesSARaward,
    info,
    positions,
    showRankWithinCompany,
    year,
    tableContainsRowDoesNotEqualTotal,
    PDFprocessing,
  ]);
  // console.log(data);
  //<Bar data={data} options={options} />
  // console.log(info);
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  useMemo(() => {
    const newYears = [];
    try {
      for (const data of executives) {
        if (data?.compensation) {
          for (const year of Object.keys(data?.compensation)) {
            if (!newYears.includes(year)) {
              newYears.push(year);
            }
          }
        }
      }
    } catch {
      newYears.push("2020");
    }
    setYearsWithData(newYears.sort((a, b) => parseInt(b) - parseInt(a)));
  }, [executives]);

  useEffect(() => {
    setYear(yearsWithData[0]);
  }, [yearsWithData, executives]);

  const yearsOptions = useMemo(() => {
    return yearsWithData.map((year, index) => (
      <option value={year} key={index}>
        {year}
      </option>
    ));
  }, [yearsWithData]);

  useEffect(() => {
    const dataPeekKeys = info?.DataPeek
      ? Object.keys(info?.DataPeek).reverse()
      : [];

    let reportYearEqualsDataYear = true;
    if (
      dataPeekKeys.length &&
      yearsWithData.length &&
      yearsWithData[0] !== dataPeekKeys[0] &&
      yearsWithData.length > dataPeekKeys.length // means there is missing data, so just display the report years instead of guessing
    ) {
      reportYearEqualsDataYear = false;
    }
    setDataPeekLinks(
      dataPeekKeys.map((year, index) => {
        const content = (
          <div key={index}>
            {info.DataPeek[year]?.url ? (
              <a
                href={info.DataPeek[year].url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`/data_peek/${info.DataPeek[year].file}?ver=${version}`}
                  style={{ width: "750px" }}
                  alt={`DataPeek data for ${info?.Ticker} ${
                    reportYearEqualsDataYear ? year : year - 1
                  }`}
                />
              </a>
            ) : (
              <img
                src={`/data_peek/${info.DataPeek[year].file}?ver=${version}`}
                style={{ width: "750px" }}
                alt={`DataPeek data for ${info?.Ticker} ${
                  reportYearEqualsDataYear ? year : year - 1
                }`}
              />
            )}
          </div>
        );
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
            key={year}
          >
            <Popover
              // style={{ width: 100 }}
              content={content}
              placement="bottomRight"
              title={
                <>
                  <p>
                    DataPeek - {info?.Ticker}{" "}
                    {reportYearEqualsDataYear ? year : year - 1}
                  </p>
                  {info.DataPeek[year]?.conversion && (
                    <p>
                      Data in this table was converted at a rate of{" "}
                      {info.DataPeek[year].conversion.amount}{" "}
                      {info.DataPeek[year].conversion.type} based on report date{" "}
                      {new Date(
                        info.DataPeek[year].conversion?.date
                      ).toLocaleDateString()}
                    </p>
                  )}
                </>
              }
            >
              <div
                style={{
                  width: 200,
                  cursor: "pointer",
                  paddingRight: "2px",
                  paddingTop: "0px",
                  paddingBottom: "4px",
                  marginTop: "-6px",
                }}
              >
                <p
                  style={{
                    textAlign: "right",
                    color: "var(--blue)",
                  }}
                >
                  DataPeek - {info?.Ticker}{" "}
                  {reportYearEqualsDataYear ? year : year - 1}
                </p>
              </div>
            </Popover>
          </div>
        );
      })
    );
  }, [info, version, yearsWithData]);

  const [pageContainsNewComp, setPageContainsNewComp] = useState(false);
  useEffect(() => {
    setPageContainsNewComp(
      executives.some((key) => isFirstYearOfComp(key, year))
    );
  }, [executives, year]);

  const sortByDate = (a, b) => {
    const dateA = a.Date ? new Date(a.Date) : null;
    const dateB = b.Date ? new Date(b.Date) : null;
    if (dateA && dateB) {
      return dateB.getTime() - dateA.getTime();
    } else if (dateA) {
      return -1; // a is a valid date, but b is not
    } else if (dateB) {
      return 1; // b is a valid date, but a is not
    } else {
      return 0; // neither a nor b is a valid date
    }
  };

  const ParagraphsFrom8k502 = () => {
    const paragraphsFrom8k502Data =
      paragraphsFrom8k502?.["Ticker"] === info?.Ticker
        ? paragraphsFrom8k502?.["502_Data"]
        : {};
    return (
      <div>
        <h2>Recent Events & Compensation Changes</h2>
        {paragraphsFrom8k502Data?.length ? (
          <div>
            {paragraphsFrom8k502Data
              .sort((a, b) => sortByDate(a, b))
              .map((data, key) => {
                const paragraphsFromReport = data?.ReleventParagraphs?.filter(
                  (paragraph) => paragraph.length > 10
                );
                return (
                  <div style={{ fontSize: "1.15em" }} key={key}>
                    <b style={{ fontSize: "1.3em" }}>
                      <a
                        style={{ color: "black", textDecoration: "none" }}
                        target="_blank"
                        href={`${data?.URL}`}
                        rel="noreferrer"
                      >
                        {new Date(data.Date).toLocaleDateString()}:
                      </a>
                    </b>
                    <ul>
                      {paragraphsFromReport.map((paragraph, key) => (
                        <li key={key} style={{ fontSize: "14px" }}>
                          {paragraph}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
          </div>
        ) : (
          <div>There are no recent compensation changes for this company.</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    setPayRatioIsCalculated(getDatapoint("PayRatio")?.calculated);
  }, [getDatapoint, info, year]);

  const [printPDFasMultiplePages, setPrintPDFasMultiplePages] = useState(false);

  const elementsIDsForPDFExport = useMemo(() => {
    // main component includes the company info and the first component on the page
    // (since they are formatted side-by-side on each page)
    const elementIDs = [["main-component"]];
    switch (pageModeSelected) {
      case "Company Overview":
        elementIDs.push(["overview-graphs"], ["recent-comp-changes"]);
        break;
      case "Company Calculators":
        elementIDs.push(["peer-group-comparison"], ["pvp-section"]);
        break;
      case "Peer Group Analysis":
        if (printPDFasMultiplePages) {
          elementIDs.push(["peer-group-table"]);
        }
        break;
      default:
        break;
    }
    return elementIDs;
  }, [pageModeSelected, printPDFasMultiplePages]);

  /* To change and reset width of component */
  useEffect(() => {
    const sleep = (milliseconds) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    const handleRerender = async (milliseconds) => await sleep(milliseconds); // Time to re-render page for formatting, may have to adjust)
    if (
      pageModeSelected === "Company Overview" &&
      document.getElementById("barChart") &&
      document.getElementById("lineGraph")
    ) {
      /* To change width of component (if doesn't fit on PDF page) */
      document.body.style.width = PDFprocessing ? "1800px" : "100%";
      document.getElementById("overview-graphs").style.height = PDFprocessing
        ? "1200px"
        : "";
      document.getElementById("barChart").style.width = PDFprocessing
        ? "100%"
        : "50%";

      document.getElementById("lineGraph").style.width = PDFprocessing
        ? "100%"
        : "45%";
    }
    if (PDFprocessing) {
      handleRerender(1500);
    }
  }, [pageModeSelected, PDFprocessing]);

  useEffect(() => {
    if (pageModeSelected !== "Company Overview") {
      setYear(yearsWithData[0]);
    }
  }, [pageModeSelected, yearsWithData]);

  const companyInfoComponent = useMemo(() => {
    return (
      <div className="col-lg-3 mb-4 company-info">
        <h1>{info?.Company}</h1>
        {pageModeSelected === "Company Overview" && (
          <div>
            Year:{" "}
            {!PDFprocessing ? (
              <select
                name="year"
                onChange={handleYearChange}
                value={year}
                style={{ textAlign: "center", height: 30 }}
              >
                {yearsOptions}
              </select>
            ) : (
              <span style={{ fontWeight: "bold" }}>{year}</span>
            )}
          </div>
        )}

        {pageModeSelected === "Company Overview" && !PDFprocessing && (
          <div style={{ paddingTop: 10 }}>
            <Checkbox
              checked={showRankWithinCompany}
              onChange={() => setShowRankWithinCompany(!showRankWithinCompany)}
            >
              Show Executive Rank (within {info?.Ticker})
            </Checkbox>
          </div>
        )}
        <br />
        <div>
          Stock Ticker:{" "}
          <a
            target="_blank"
            href={`${ETRADE_URL}${info?.Ticker}`}
            rel="noreferrer"
          >
            {info?.Ticker}
          </a>
        </div>
        {info?.AlternateTickers?.length ? (
          <div>
            Alternate Ticker
            {info?.AlternateTickers?.length > 2 && "s"}:{" "}
            <span>
              {info?.AlternateTickers.filter(
                (ticker) => ticker !== info?.Ticker
              ).join(", ")}
            </span>
          </div>
        ) : null}
        <div>
          Sector: <span>{info?.Sector || "Other"}</span>
        </div>
        <div>
          Industry: <span>{info?.Industry || "Other"}</span>
        </div>
        <div>
          SIC: <span>{info?.SIC || "N/A"}</span>
        </div>
        <div>
          <div>
            {year} Assets:{" "}
            <span>
              {getMeasure("Assets")?.value !== "N/A"
                ? "$" + getMeasure("Assets")?.value?.toLocaleString()
                : "N/A"}
            </span>
          </div>
          {year} Revenue:{"  "}
          <span>
            {getDatapoint("Revenue")?.value !== "N/A"
              ? "$" + getDatapoint("Revenue")?.value?.toLocaleString()
              : "N/A"}
          </span>
        </div>
        <div>
          {year} Profit (Loss):{" "}
          {renderNetIncome(getDatapoint("NetIncome")?.value)}
        </div>
        <div>
          {year} CEO Pay Ratio:{" "}
          {getDatapoint("PayRatio")?.value !== "N/A" ? (
            <span>
              {formatMeasure(getDatapoint("PayRatio").value, "ratio")} : 1
              {payRatioIsCalculated && <sup> †</sup>}
            </span>
          ) : (
            <span>N/A</span>
          )}
        </div>
        <div>
          Current Market Cap:{" "}
          <span>
            {marketCap ? "$" + parseInt(marketCap).toLocaleString() : "N/A"}
          </span>
        </div>
        <div>
          Current Employee Count:{" "}
          <span>
            {info?.Employees &&
            parseInt(info?.Employees.toString().replace(",", "")) >= 0
              ? info?.Employees?.toLocaleString()
              : "N/A"}
          </span>
        </div>
        <div>
          Location: <span>{info?.Address || "N/A"}</span>
        </div>
        <div style={{ marginTop: 6 }}>
          <span>
            {payRatioIsCalculated && payRatioCalculatedMessage(info, year)}
          </span>
        </div>
        <div className="exportPDF" style={{ marginTop: 6 }}>
          <ExportPDFButton
            setPDFprocessing={setPDFprocessing}
            elementIDgroups={elementsIDsForPDFExport}
          />
        </div>
      </div>
    );
  }, [
    PDFprocessing,
    elementsIDsForPDFExport,
    info,
    marketCap,
    pageModeSelected,
    getDatapoint,
    getMeasure,
    payRatioIsCalculated,
    showRankWithinCompany,
    year,
    yearsOptions,
  ]);

  return (
    <div className="overview container mb-3" style={{ minHeight: "90vh" }}>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
          marginTop: "100px",
        }}
      >
        <Radio.Group
          // defaultValue={initialSelectorOptions[0]}
          size="medium"
          onChange={(e) => {
            setPageModeSelected(e.target.value);
          }}
          value={pageModeSelected}
        >
          {pageModeOptions
            .filter((item) => compAIaccess || item !== "Comp AI™ (alpha)")
            .map((option, index) => (
              <Link
                to={
                  reverseHashMap?.[option]
                    ? `${location.pathname}#${reverseHashMap[option]}`
                    : `${location.pathname}`
                }
                onClick={() => setPageModeSelected(option)}
                style={{ color: "inherit" }}
                key={index}
              >
                <Radio.Button
                  value={option}
                  key={option}
                  className="custom-radio-button"
                  style={{ margin: 5 }}
                >
                  {option}
                </Radio.Button>
              </Link>
            ))}
        </Radio.Group>
      </div>

      <div>
        {!loading && companyNotFound ? (
          <div>
            <NotFound />
          </div>
        ) : loading ? (
          <center
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PuffLoader color={"var(--loader)"} loading={loading} size={100} />
          </center>
        ) : (
          <div>
            <div className="container">
              <div className="row">
                <div
                  className="row"
                  id={
                    !["Company Calculators", "Peer Group Analysis"].includes(
                      pageModeSelected
                    )
                      ? "main-component"
                      : ""
                  }
                >
                  {
                    // All pages besides these 2 have the company overview imported for formatting, still show here to display upsell blurbs properly
                    ((!isTier2 && !peerGroupAccess) ||
                      !["Company Calculators", "Peer Group Analysis"].includes(
                        pageModeSelected
                      )) &&
                      companyInfoComponent
                  }

                  {
                    //Special case for customers who can access peer group table, still show the company info while showing upsell blurbs
                    !isTier2 &&
                      peerGroupAccess &&
                      ["Company Calculators"].includes(pageModeSelected) &&
                      companyInfoComponent
                  }

                  {pageModeSelected === "Company Calculators" && (
                    <CompanyCalculator
                      companyInfo={info}
                      companyInfoComponent={companyInfoComponent}
                      companyExecutives={executives}
                      PDFprocessing={PDFprocessing}
                    />
                  )}
                  {pageModeSelected === "Board Pay" && (
                    <BoardPay
                      companyInfo={info}
                      PDFprocessing={PDFprocessing}
                    />
                  )}
                  {pageModeSelected === "Peer Group Analysis" && (
                    <PeerGroupAnalysis
                      companyInfo={info}
                      companyInfoComponent={companyInfoComponent}
                      PDFprocessing={PDFprocessing}
                      printPDFasMultiplePages={printPDFasMultiplePages}
                      setPrintPDFasMultiplePages={setPrintPDFasMultiplePages}
                    />
                  )}
                  {pageModeSelected === "Options Calculators" && (
                    <OptionsCalculators companyInfo={info} />
                  )}
                  {pageModeSelected === "rTSR Calculators" && (
                    <RTSRCalculators companyInfo={info} />
                  )}
                  {pageModeSelected === "Incentive Structure" && (
                    <IncentiveStructure
                      companyInfo={info}
                      PDFprocessing={PDFprocessing}
                    />
                  )}
                  {pageModeSelected === "Risk" && (
                    <RiskModule
                      companyInfo={info}
                      PDFprocessing={PDFprocessing}
                    />
                  )}
                  {pageModeSelected === "Comp AI™" && (
                    <CompAI companyInfo={info} />
                  )}
                  {pageModeSelected === "Company Overview" && (
                    <div className="col-lg-9 executives p-0">
                      {PDFprocessing ? (
                        <h3>Executive Compensation Overview</h3>
                      ) : (
                        <div className="row">
                          <div className="col-md d-flex justify-content-start align-items-end pb-3">
                            <p
                              style={{
                                fontSize: 18,
                                lineHeight: 1.4,
                              }}
                            >
                              CSV&nbsp;Export:&nbsp;
                            </p>
                            <Select
                              options={CSVExportOptions}
                              value={CSVExportOptionSelected}
                              onChange={(value) =>
                                setCSVExportOptionSelected(value)
                              }
                              style={{
                                width:
                                  (customUserPeerGroups &&
                                    Object.keys(customUserPeerGroups).length) ||
                                  CSVExportOptions.some(
                                    (item) =>
                                      item.label.includes("Performance") ||
                                      item.label.includes("Compensation")
                                  )
                                    ? 230
                                    : 150,
                                textAlign: "center",
                                paddingRight: 5,
                              }}
                              dropdownStyle={{ textAlign: "center" }}
                              defaultValue={"company"}
                            ></Select>
                            {CSVExportOptionSelected === "company" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(executives),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Company Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected === "analyst" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        analystCohortExecutives
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Analyst Cohort Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected === "proxy" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(proxyCohortExecutives),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Proxy Cohort Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected ===
                              "proxyCompensation" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        compensationCohortExecutives
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Proxy Cohort Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected === "compAIproxy" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        compAIProxyCohortExecutives
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Proxy Cohort Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected ===
                              "compAIproxyCompensation" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        compAICompensationCohortExecutives
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Proxy Cohort Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected ===
                              "compAIproxyPerformance" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        compAIPerformanceCohortExecutives
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Proxy Cohort Data to CSV
                              </Button>
                            )}
                            {CSVExportOptionSelected === "proxyPerformance" && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        performanceCohortExecutives
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Proxy Cohort Data to CSV
                              </Button>
                            )}
                            {isCustomPeerGroup(CSVExportOptionSelected) && (
                              <Button
                                onClick={() =>
                                  exportToCSV(
                                    formatExecutiveDataForExport(
                                      executives.concat(
                                        allExecutives.filter((exec) =>
                                          CSVExportOptions.find(
                                            (item) =>
                                              item.label ===
                                              CSVExportOptionSelected
                                          ).tickers.includes(exec.Ticker)
                                        )
                                      ),
                                      true
                                    ),
                                    `${info?.Ticker} ${
                                      CSVExportOptions.filter(
                                        (opt) =>
                                          opt.value === CSVExportOptionSelected
                                      )[0].label
                                    } Executive Data ${
                                      new Date().toString().split("GMT-")[0]
                                    }.csv`
                                  )
                                }
                              >
                                Export Custom Cohort Data to CSV
                              </Button>
                            )}
                          </div>
                          <div className="col-md">{dataPeekLinks}</div>
                        </div>
                      )}
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {showRankWithinCompany
                                ? `Name, Principal Positions, and Rank (within ${info.Ticker}) in ${year}`
                                : `Name and Principal Positions in ${year}`}
                            </th>
                            <th>Year</th>
                            <th className="d-none d-md-table-cell">
                              Base Salary
                            </th>
                            <th className="d-none d-md-table-cell">
                              Bonus Payout
                            </th>
                            <th className="d-none d-md-table-cell">
                              Stock Awards
                            </th>
                            {includesRSUs && (
                              <th className="d-none d-md-table-cell">
                                Restricted Stock Unit Awards
                              </th>
                            )}
                            {includesPSUs && (
                              <th className="d-none d-md-table-cell">
                                Performance Stock Unit Awards
                              </th>
                            )}
                            <th className="d-none d-md-table-cell">
                              Option Awards
                            </th>
                            {includesSARaward && (
                              <th className="d-none d-md-table-cell">
                                SAR Awards
                              </th>
                            )}
                            <th className="d-none d-md-table-cell">
                              Non-Equity Incentive Plan Compensation
                            </th>
                            <th className="d-none d-md-table-cell">
                              Change in Pension Value & Nonqualified Deferred
                              Compensation Earnings
                            </th>
                            <th className="d-none d-md-table-cell">
                              All Other Compensation
                            </th>
                            <th>
                              <b>Total Compensation</b>
                            </th>
                            {tableContainsRowDoesNotEqualTotal && (
                              <th>
                                <b>
                                  Sum of Compensation Components (if
                                  discrepancy)
                                </b>
                              </th>
                            )}
                            {tableContainsRowDoesNotEqualTotal && (
                              <th>
                                <b>
                                  Discrepancy (Reported Total - Sum of
                                  Components)
                                </b>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>{renderCompensation}</tbody>
                      </table>
                      <div>
                        {pageContainsNewComp
                          ? `* ${year} was this executive's first year of compensation.`
                          : ""}
                      </div>
                      <div>
                        {tableContainsRowDoesNotEqualTotal
                          ? `** C-Suite Comp detects a potential data discrepancy for this officer as the reported sub-components of their pay do not sum to the reported total.`
                          : ""}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {pageModeSelected === "Company Overview" && (
              <div>
                <div className="c-table">
                  <div className="graphs" id="overview-graphs">
                    {PDFprocessing && (
                      <h3 style={{ width: "100%" }}>
                        Executive Compensation Graphs
                      </h3>
                    )}
                    <BarGraphOverview
                      data={executives}
                      year={year}
                      setYear={setYear}
                      yearsOptions={yearsOptions}
                      company={info?.Company}
                      id="barChart"
                      PDFprocessing={PDFprocessing}
                    />
                    <LineGraph
                      data={lineGroup}
                      currentYear={year}
                      company={info?.Company}
                      id="lineGraph"
                      PDFprocessing={PDFprocessing}
                    />
                  </div>
                </div>
                <div className="pt-0">
                  <DisclosureSearchBar companyInfo={info} />
                  <hr className="mt-4" />
                </div>
                <div id="recent-comp-changes">
                  <ParagraphsFrom8k502 />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

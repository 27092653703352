import { CSuiteBaseService } from "./CSuiteBaseServices";

class CompanyService extends CSuiteBaseService {
  getAllMarketCap = () => {
    return this.get(`company/get-all-marketcap`);
  };

  // getAllCompanies = () => {
  //   return this.get(`company/get-all-companies`);
  // };

  // getAllExecutives = () => {
  //   return this.get(`executive/get-all-executives`);
  // };

  getExecutivesFromCompany = (ticker) => {
    return this.get(`executive/get-executives?ticker=${ticker}`);
  };

  getMarketCap = (ticker) => {
    return this.get(`company/get-marketcap?ticker=${ticker}`);
  };

  getMonthlyReturns = (tickers, token) => {
    return this.get(
      `company/get-monthly-returns?tickers=${tickers}&token=${token}`
    );
  };

  getWeeklyReturns = (tickers, token) => {
    return this.get(
      `company/get-weekly-returns?tickers=${tickers}&token=${token}`
    );
  };

  getDailyPrices = (tickers, token) => {
    return this.get(
      `company/get-daily-prices?tickers=${tickers}&token=${token}`
    );
  };

  getAwards = (tickers, token) => {
    return this.get(`company/get-awards?tickers=${tickers}&token=${token}`);
  };

  getRiskFactors = (tickers, token) => {
    return this.get(
      `company/get-risk-factors?tickers=${tickers}&token=${token}`
    );
  };

  getMonthlyMarketcaps = (tickers, token) => {
    return this.get(
      `company/get-monthly-marketcaps?tickers=${tickers}&token=${token}`
    );
  };

  getBoardPay = (ticker, token) => {
    return this.get(`company/get-board-pay?ticker=${ticker}&token=${token}`);
  };

  getBoardPayProfiles = (ticker, token) => {
    return this.get(
      `company/get-board-pay-profiles?ticker=${ticker}&token=${token}`
    );
  };

  getAllBoardPay = (token) => {
    return this.get(`company/get-all-board-pay?token=${token}`);
  };

  getIndexMonthlyReturns = (token) => {
    return this.get(`company/get-monthly-index-returns?token=${token}`);
  };

  getMedianByYear = (year) => {
    return this.get(`company/get-median-pay-from-year?year=${year}`);
  };

  getParagraphsFrom8k502 = (ticker) => {
    return this.get(`company/get-paragraphs-from-8k502?ticker=${ticker}`);
  };

  compAISearch = (searchParams, token) => {
    return this.post(`company/comp-ai-search?token=${token}`, searchParams);
  };

  compAIAvailability = (ticker, token) => {
    return this.post(`company/comp-ai-availability?token=${token}`, ticker);
  };

  compAIResultRating = (ratingDetails, token) => {
    return this.post(
      `company/comp-ai-result-rating?token=${token}`,
      ratingDetails
    );
  };
}

export default new CompanyService();

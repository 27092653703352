import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Progress,
  Modal,
  Checkbox,
  Col,
  Row,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import "../../css/c-table.css";
import dataFilter from "../../utils/DataFilter/dataFilter";
import dataFilterLine from "../../utils/DataFilter/dataFilterLine";

import { useDispatch, useSelector } from "react-redux";
// import ComparisonTable from "./ComparisonTable";
// import { toggleComparisonFilter } from "../../redux/actions/ComparisonTableAction";
import TableFilter from "./TableFilter";
import { setCSuite } from "../../redux/actions/TableFilterAction";
import { setFirstTableLoad } from "../../redux/actions/TableFilterAction";
import { showModal } from "../../redux/actions/ModalAction";
import SummaryStatTable from "./SummaryStatTable";
import BarGraph from "../Graphs/BarGraph";
import LineGraph from "../Graphs/LineGraph";
import { createColumn } from "../../utils/DataFilter/createColumn";
import {
  isFirstYearOfComp,
  toDollar,
  emdash,
  dataHasDiscrepancy,
  MIN_YEAR,
  DEFAULT_YEAR,
  getMeasure,
  formatMeasure,
} from "../../utils/Constants/systemSetting";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import { setTableFilter } from "../../redux/actions/TableFilterAction";
import { executivesTotal } from "../../utils/Constants/executiveCount.js";
import { boardMembersTotal } from "../../utils/Constants/boardMemberCount.js";
import { getAllBoardPay } from "../../redux/actions/CompanyAction.js";
const CpositionsList = require("../../assets/CpositionsList.json");

export default function CSuiteTable() {
  const dispatch = useDispatch();

  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { csuite: initialCsuiteData } = useSelector(
    (state) => state.ExecutivesReducer
  );
  const { marketCap } = useSelector((state) => state.CompaniesReducer);
  const { filter, csuite, firstTableLoad } = useSelector(
    (state) => state.TableFilterReducer
  );
  const { allBoardPay } = useSelector((state) => state.CompanyReducer);

  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const [index, setIndex] = useState(0);

  const [isLoading, setLoading] = useState(initialCsuiteData.length < 1);
  const [yearFilteredData, setYearFilteredData] = useState(initialCsuiteData);

  const [sortDirection, setSortDirection] = useState();
  const [sortColumn, setSortColumn] = useState();
  const columnBreakpoint = 1450; //px
  const [hideColumns, setHideColumns] = useState(
    window.innerWidth < columnBreakpoint
  );

  const [progressPercent, setProgressPercent] = useState(
    initialCsuiteData.length > 1 ? 101 : 0
  );

  const [currentTime, setCurrentTime] = useState(new Date());
  const [delay, setDelay] = useState(false);
  const [update, setUpdate] = useState(false);
  const [group, setGroup] = useState([]);

  const [useCalculatedTotals, setUseCalculatedTotals] = useState(false);

  const [triggerDelay, setTriggerDelay] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  //// TODO: Enable state change version if exporting the executive table
  //// Will allow for formatting of the table when enabled
  // and set allowExecutiveTableExport to false if not using (should not be done for users)
  // const [PDFprocessing, setPDFprocessing] = useState(false);
  const allowExecutiveTableExport = false;
  const PDFprocessing = false;
  const setPDFprocessing = () => null;

  const handleResetCheck = () => {
    const checkboxes = document.querySelectorAll(".company-checkbox");
    checkboxes.forEach((el) => (el.checked = false));
    setGroup([]);
  };

  // For all users is a clone of pdfprocessing, but will allow for style changes all the time
  // e.g. line graph needs a legend when PDF is processing
  const [PDFprocessingForAllUsers, setPDFprocessingForAllUsers] =
    useState(false);

  const [loadingBoardPay, setLoadingBoardPay] = useState(false);
  useMemo(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (csuite.includes("Board Pay") && !loadingBoardPay && !allBoardPay) {
      if (progressPercent >= 100) {
        setLoadingBoardPay(true);
        setProgressPercent(0);
        dispatch(getAllBoardPay(token));
        dispatch(setFirstTableLoad(true));
      }
      setTriggerUpdate(true);
    }
  }, [csuite, loadingBoardPay, allBoardPay, progressPercent, dispatch]);

  // Used to swap between calculated totals and original totals
  const csuiteData = useMemo(() => {
    let data = initialCsuiteData;
    if (csuite.includes("Board Pay")) {
      if (!allBoardPay) {
        setTriggerUpdate(true);
        return [];
      }
      if (allBoardPay) {
        data = Object.values(allBoardPay);
      }
    }

    if (data && data.length) {
      if (!useCalculatedTotals) {
        return data;
      } else {
        const validYears = () => {
          let year = new Date().getFullYear();
          const yearsArray = [];
          for (year; year >= MIN_YEAR; year--) {
            yearsArray.push(year);
          }
          return yearsArray;
        };
        const validYearsArray = validYears();
        const newCompData = {};
        const adjustedData = data.map((item) => {
          validYearsArray.forEach((year) => {
            const compensationForYear = {
              ...item.compensation[year],
              total:
                item.compensation?.[year]?.["sumOfCompensationComponents"] ||
                (item.compensation?.[year]?.["total"] === 0
                  ? 0
                  : item.compensation?.[year]?.["total"] || undefined),
            };
            newCompData[`${year}`] = compensationForYear;
          });

          return Object.assign({}, item, {
            compensation: {
              ...item.compensation,
              ...newCompData,
            },
          });
        });
        return adjustedData;
      }
    }
    return [];
  }, [initialCsuiteData, allBoardPay, useCalculatedTotals, csuite]);

  useEffect(() => {
    if (csuite.includes("Board Pay")) {
      if (allBoardPay) {
        setLoadingBoardPay(false);
      } else {
        // setLoadingBoardPay(true);
        // setProgressPercent(0);
        // dispatch(setFirstTableLoad(true));
      }
    }
  }, [allBoardPay, csuite, dispatch]);

  useEffect(() => {
    if (isLoading || loadingBoardPay) {
      if (new Date().getMilliseconds() - currentTime.getMilliseconds() > 1000) {
        setCurrentTime(new Date());
      }
    }
  }, [currentTime, isLoading, loadingBoardPay]);

  // For resetting table pagination when reset button is clicked
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    current: 1,
    pageSize: 10,
  });
  const handleResetPagination = () => {
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  useEffect(() => {
    if (PDFprocessing) {
      setPagination({
        position: ["bottomCenter"],
        current: 1,
        pageSize: 30,
        hideOnSinglePage: true,
      });
    }
  }, [PDFprocessing]);

  useEffect(() => {
    if (firstTableLoad) {
      function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }
      if ((isLoading || loadingBoardPay) && currentTime) {
        setTimeout(() => {
          setProgressPercent(
            progressPercent +
              Math.floor(
                getRandomArbitrary(
                  progressPercent < 90 ? 2 : 0,
                  progressPercent < 90 ? 5 : 100 - progressPercent
                )
              )
          );
        }, 250);
      } else if (!isLoading && !loadingBoardPay) {
        if (progressPercent < 100) {
          setProgressPercent(100);
        } else if (progressPercent === 100) {
          setTriggerUpdate(true);
          setTimeout(() => {
            setProgressPercent(101);
          }, 350);
          dispatch(setFirstTableLoad(false));
        }
      }
    }
  }, [
    isLoading,
    loadingBoardPay,
    firstTableLoad,
    progressPercent,
    currentTime,
    dispatch,
  ]);

  useEffect(() => {
    if (filter.immediateUpdate) {
      setTriggerUpdate(true);
      dispatch(setTableFilter({ ...filter, immediateUpdate: false }));
    }
  }, [filter]);

  useEffect(() => {
    if (triggerUpdate || update) {
      setUpdate(true);
      let timer = setTimeout(() => {
        setUpdate(false);
      }, 10);
      setTriggerUpdate(false);
      return () => clearTimeout(timer);
    } else if (triggerDelay || delay) {
      setDelay(true);
      let timer = setTimeout(() => {
        setDelay(false);
      }, 800);
      setTriggerDelay(false);

      return () => clearTimeout(timer);
    }
    setTriggerUpdate(false);
  }, [triggerDelay, triggerUpdate, update, delay]); //[filter, csuite, csuiteData, group]

  useEffect(() => {
    if (!firstTableLoad) {
      setTriggerUpdate(true);
      setDelay(false);
    }
  }, [firstTableLoad]);

  const sortCol = useRef();
  const sortDir = useRef();
  sortCol.current = sortColumn;
  sortDir.current = sortDirection;

  const updateHideColumns = () => {
    setHideColumns(window.innerWidth < columnBreakpoint);
  };
  useEffect(() => {
    window.addEventListener("resize", updateHideColumns);
    return () => window.removeEventListener("resize", updateHideColumns);
  });
  useEffect(() => {
    if (csuiteData.length > 1 && Object.keys(companies).length >= 9) {
      setLoading(false);
    }
  }, [csuiteData, companies]);

  const [data, setData] = useState([]);

  const [showComparisonTable] = useState(0);

  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const handleCheck = useCallback(
    (e, record) => {
      if (e.target.checked === true) {
        group.push(record);
        // console.log(record)
      } else {
        const index = group.findIndex((item) => item.key === record.key);
        if (index !== -1) {
          group.splice(index, 1);
        }
      }
      setGroup([...group]);
    },
    [group]
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          //   ref={node => {
          //     this.searchInput = node;
          //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //useCallback keeps currently sorted column accurate
  const onChange = useCallback((pagination, filters, sorter, extra) => {
    setSortDirection(sorter.order);
    setSortColumn(sorter.field);
    setPagination(pagination);
    // console.log("params", pagination, filters, sorter, extra);
  }, []);

  const calcOther = (compensation) => {
    if (compensation?.SARaward && compensation?.other) {
      return compensation.SARaward + compensation.other;
    } else if (compensation?.other) {
      return compensation?.other;
    } else if (compensation?.SARaward) {
      return compensation?.SARaward;
    }
    return;
  };

  const calcStockAwards = (compensation) => {
    let sum =
      (compensation?.stockAward || 0) +
      (compensation?.RSUs || 0) +
      (compensation?.PSUs || 0);
    return sum;
  };

  function handleSort(a, b, negativeAllowed = false, zeroAllowed = false) {
    const sortVar = (x) => {
      sortDir.current == "ascend" ? (x = Infinity) : (x = -Infinity);
      return x;
    };
    if (
      a == null ||
      isNaN(a) ||
      typeof a == "undefined" ||
      a.toString().includes("N") ||
      a.toString().includes("–")
    ) {
      a = sortVar(a);
    } else if ((a < 0 && !negativeAllowed) || (a === 0 && !zeroAllowed)) {
      a = sortVar(a);
    }
    if (
      b == null ||
      isNaN(b) ||
      typeof b == "undefined" ||
      b.toString().includes("N") ||
      b.toString().includes("–")
    ) {
      b = sortVar(b);
    } else if ((b < 0 && !negativeAllowed) || (b === 0 && !zeroAllowed)) {
      b = sortVar(b);
    }
    return a - b;
  }

  // const ceo = useMemo(
  //   () =>
  //     createColumn(
  //       "ceo",
  //       handleCheck,
  //       handleResetCheck,
  //       filter.currentYear,
  //       handleSort
  //     ),
  //   []
  // );
  const allExecutivesRef = useRef();
  const formatPositions = (positions) => {
    if (positions) {
      return positions.join(", ");
    }
    return "–";
  };

  const [boardPaySwitched, setBoardPaySwitched] = useState(false);
  const [prevCsuite, setPrevCsuite] = useState([]);
  useEffect(() => {
    if (
      (prevCsuite.includes("Board Pay") && !csuite.includes("Board Pay")) ||
      (!prevCsuite.includes("Board Pay") && csuite.includes("Board Pay"))
    ) {
      setBoardPaySwitched(true);
    }
    setPrevCsuite(csuite);
  }, [csuite, prevCsuite]);

  const [columnOptions, setColumnOptions] = useState([]);
  const [activeColumns, setActiveColumns] = useState([]);

  const currentColumns = useMemo(() => {
    let defaultColumns = [
      {
        title: "Company",
        titleString: "Company",
        dataIndex: "company",
        align: "left",
        dataType: "string",
        PDFprocessingLineHeight: 1.1,
        isDefaultSort: "ascend",
        isCompanyColumn: true,
        isDefault: true,
      },
      // {
      //   title: "Ticker",
      //   titleString: "Ticker",
      //   dataIndex: "Ticker",
      //   align: "left",
      //   dataType: "string",
      //   PDFprocessingLineHeight: 1.1,
      //   isDefaultSort: "ascend",
      //   isCompanyColumn: true,
      //   responsive: [],
      //   isDefault: true,
      //   isHidden: true,
      // },
      {
        title: "Sector",
        titleString: "Sector",
        dataIndex: "Sector",
        dataType: "string",
        responsive: ["lg"],
        PDFprocessingEllipsis: true,
        isCompanyData: true,
        isYearIndependant: true,
      },
      {
        title: "Industry",
        titleString: "Industry",
        dataIndex: "industry",
        dataType: "string",
        responsive: ["lg"],
        PDFprocessingEllipsis: true,
        isDefault: true,
      },
      {
        title: "Name",
        titleString: `${
          csuite.includes("Board Pay") ? "Board Member" : "Executive"
        } Name`,
        dataIndex: "name",
        dataType: "string",
        isNameColumn: true,
        isDefault: true,
      },
      {
        title: "Title",
        titleString: "Title",
        dataIndex: "position",
        dataType: "string",
        isPositionColumn: true,
        isDefault: true,
        disabledForBoardPay: true,
      },
      {
        title: csuite.includes("Board Pay")
          ? "Fees Earned or Paid in Cash"
          : "Base Salary",
        titleString: csuite.includes("Board Pay")
          ? "Fees Earned or Paid in Cash"
          : "Base Salary",
        compensationIndex: "salary",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: "Bonus Payout",
        titleString: "Bonus Payout",
        compensationIndex: "bonus",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: "Stock Awards",
        titleString: "Stock Awards",
        compensationIndex: "stockAward",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: "Option Awards",
        titleString: "Option Awards",
        compensationIndex: "optionAward",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: (
          <Tooltip
            overlayInnerStyle={{
              padding: "10px",
              color: "var(--secondary)",
            }}
            color="white"
            title="Non-Equity Incentive Plan Compensation"
            placement="top"
          >
            Non-Equity Incentive...
          </Tooltip>
        ),
        titleString: "Non-Equity Incentive...",
        compensationIndex: "nonEquity",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: (
          <Tooltip
            overlayInnerStyle={{
              padding: "10px",
              color: "var(--secondary)",
            }}
            color="white"
            title="Change in Pension Value & Nonqualified Deferred Compensation Earnings"
            placement="top"
          >
            Change in Pension...
          </Tooltip>
        ),
        titleString: "Change in Pension...",
        compensationIndex: "pension",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: "All Other Compensation",
        titleString: "All Other Compensation",
        compensationIndex: "other",
        dataIndex: "compensation",
        dataType: "int",
        hideOnResponsive: true,
        isDefault: true,
      },
      {
        title: <b>Total Compensation</b>,
        titleString: "Total Compensation",
        compensationIndex: "total",
        dataIndex: "compensation",
        dataType: "int",
        isTotalColumn: true,
        isDefault: true,
      },
      {
        title: "Employees",
        titleString: "Employees",
        dataIndex: "Employees",
        dataType: "int",
        isCompanyData: true,
        isYearIndependant: true,
      },
      {
        title: "Current Market Cap",
        titleString: "Current Market Cap",
        dataIndex: "MarketCap",
        dataType: "dollar",
        isCompanyData: true,
        isYearIndependant: true,
      },
      {
        title: "Assets",
        titleString: "Assets",
        dataIndex: "Assets",
        dataType: "dollar",
        isCompanyData: true,
        isMeasure: true,
      },
      {
        title: "Net Income",
        titleString: "Net Income",
        dataIndex: "NetIncome",
        dataType: "dollar",
        isCompanyData: true,
        negativeAllowed: true,
      },
      {
        title: "Revenue",
        titleString: "Revenue",
        dataIndex: "Revenue",
        dataType: "dollar",
        isCompanyData: true,
      },
      {
        title: "Company CEO Pay Ratio",
        titleString: "CEO Pay Ratio",
        dataIndex: "PayRatio",
        dataType: "int",
        isCompanyData: true,
        isPayRatio: true,
      },
      {
        title: "Executive Rank (Within Company)",
        titleString: "Executive Rank",
        compensationIndex: "rank",
        dataIndex: "compensation",
        dataType: "int",
        isRank: true,
        disabledForBoardPay: true,
      },
    ];

    setColumnOptions(
      defaultColumns
        .filter(
          (item) => !item.disabledForBoardPay || !csuite.includes("Board Pay")
        )
        .map((item) => item.titleString)
    );

    if (
      !activeColumns.length ||
      (boardPaySwitched &&
        ((csuite.includes("Board Pay") &&
          !activeColumns.some(
            (item) => item === "Fees Earned or Paid in Cash"
          )) ||
          (!csuite.includes("Board Pay") &&
            !activeColumns.some((item) => item === "Base Salary"))))
    ) {
      setActiveColumns(
        defaultColumns
          .filter((item) => item?.isDefault)
          .map((item) => item.titleString)
      );
      setBoardPaySwitched(false);
    }

    const currentColumns = defaultColumns.filter((item) =>
      activeColumns.includes(item.titleString)
    );

    return currentColumns;
  }, [csuite, boardPaySwitched, activeColumns]);

  useMemo(() => {
    if (
      boardPaySwitched &&
      csuite.includes("Board Pay") &&
      activeColumns.some((item) => item === "Title")
    ) {
      setActiveColumns((prev) => [...prev.filter((item) => item !== "Title")]);
    }
  }, [csuite, activeColumns, boardPaySwitched]);

  const ColumnSelectionModal = () => {
    const [visible, setVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(activeColumns);

    const onCheckAllChange = (e) => {
      if (columnOptions.length !== selectedColumns.length) {
        setSelectedColumns(columnOptions);
      } else {
        setSelectedColumns([]);
      }
    };

    const handleCheckboxChange = (checkedValues) => {
      setSelectedColumns(checkedValues);
    };

    const showModal = () => {
      setVisible(true);
    };

    const handleOk = () => {
      setVisible(false);
      setActiveColumns(selectedColumns);
    };

    const handleCancel = () => {
      setVisible(false);
    };

    return (
      <>
        <Button type="primary" onClick={showModal}>
          Edit Table Columns
        </Button>
        <Modal
          title="Add/Remove Table Columns"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"700px"}
          closable={selectedColumns.length}
          okButtonProps={{ disabled: selectedColumns.length <= 0 }}
        >
          <Checkbox.Group
            // options={columnOptions}
            value={selectedColumns}
            onChange={handleCheckboxChange}
            style={{ width: 700 }}
          >
            <Row>
              {columnOptions.map((item) => (
                <Col span={8}>
                  <Checkbox checked={columnOptions.includes(item)} value={item}>
                    {item}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
          <Checkbox
            style={{ marginTop: 10 }}
            onChange={onCheckAllChange}
            checked={columnOptions.length === selectedColumns.length}
          >
            Select all
          </Checkbox>
          {!selectedColumns.length ? (
            <p style={{ textAlign: "center" }}>
              Please select at least one column.
            </p>
          ) : null}
        </Modal>
      </>
    );
  };

  useMemo(() => {
    const formatPosition = (position) => {
      for (const [key, value] of Object.entries(position)) {
        if (
          Object.keys(position).includes(filter.currentYear) &&
          position[filter.currentYear].length
        ) {
          return PDFprocessing ? (
            <p
              style={{
                lineHeight: 1.25,
              }}
            >
              {formatPositions(position[filter.currentYear])}
            </p>
          ) : (
            formatPositions(position[filter.currentYear])
          );
        } else if (
          key > filter.currentYear &&
          !Object.keys(position).includes(filter.currentYear) &&
          value.length
        ) {
          return formatPositions(value);
        }
      }
      return "N/A";
    };

    const sortString = (a, b, dataIndex) => {
      const sortVar = (x) => {
        sortDir.current === "ascend" ? (x = Infinity) : (x = -Infinity);
        return x;
      };
      let aValue = a?.[dataIndex]?.trim()?.toLowerCase() || "";
      let bValue = b?.[dataIndex]?.trim()?.toLowerCase() || "";

      if (!aValue) aValue = sortVar(aValue);
      if (!bValue) bValue = sortVar(bValue);

      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      } else {
        return 0;
      }
    };
    allExecutivesRef.current = currentColumns.map((column) => ({
      title: <center>{column.title}</center>,
      dataIndex: column.dataIndex,
      key: column?.compensationIndex || column.dataIndex,
      align: column.align || "center",
      fixed: column.align || "center",
      defaultSortOrder: column.isDefaultSort ? column.isDefaultSort : "",
      responsive: column.responsive ? column.responsive : null,
      className: column.hideOnResponsive && hideColumns ? "d-none" : "",
      ellipsis:
        PDFprocessing &&
        allowExecutiveTableExport &&
        column.PDFprocessingEllipsis
          ? true
          : false,
      sorter: column.isPositionColumn
        ? null
        : {
            compare: (a, b) => {
              return column.dataType === "string"
                ? sortString(
                    column?.isCompanyData ? companies[a.Ticker] : a,
                    column?.isCompanyData ? companies[b.Ticker] : b,
                    column.dataIndex
                  )
                : column?.isCompanyData && !column?.isMeasure
                ? handleSort(
                    parseInt(
                      column?.isYearIndependant
                        ? companies[a.Ticker]?.[column.dataIndex]
                        : companies[a.Ticker]?.[column.dataIndex]?.[
                            filter.currentYear
                          ]?.value
                    ),
                    parseInt(
                      column?.isYearIndependant
                        ? companies[b.Ticker]?.[column.dataIndex]
                        : companies[b.Ticker]?.[column.dataIndex]?.[
                            filter.currentYear
                          ]?.value
                    ),
                    column?.negativeAllowed || false,
                    true
                  )
                : column?.isCompanyData && column?.isMeasure
                ? handleSort(
                    parseInt(
                      getMeasure(
                        companies[a.Ticker],
                        filter.currentYear,
                        column.dataIndex
                      )?.value
                    ),
                    parseInt(
                      getMeasure(
                        companies[b.Ticker],
                        filter.currentYear,
                        column.dataIndex
                      )?.value
                    ),
                    column?.negativeAllowed || false,
                    true
                  )
                : handleSort(
                    parseInt(
                      a.compensation?.[filter.currentYear]?.[
                        column?.compensationIndex
                      ]
                    ) || "N/A",
                    parseInt(
                      b.compensation?.[filter.currentYear]?.[
                        column?.compensationIndex
                      ]
                    ) || "N/A"
                  );
            },
          },
      render: (text, record) => {
        if (column.isName) {
          const formattedName = isFirstYearOfComp(record, filter.currentYear)
            ? text + "*"
            : text;
          text = formattedName;
        }
        if (column.isPositionColumn) {
          text = formatPosition(text);
        }
        if (column.isCompanyData && column.isMeasure) {
          text = getMeasure(
            companies[record.Ticker],
            filter.currentYear,
            column.dataIndex
          )?.value;
        }
        if (column.isCompanyData && !column.isMeasure) {
          text = column?.isYearIndependant
            ? companies[record.Ticker]?.[column.dataIndex]
            : companies[record.Ticker]?.[column.dataIndex]?.[filter.currentYear]
                ?.value;
        }

        return (
          <div
            className={`table-header d-flex align-items-center ${
              column.align !== "left" ? "justify-content-center" : ""
            }`}
          >
            {PDFprocessing ? (
              <p
                style={{
                  lineHeight: column.PDFprocessingLineHeight || 1.1,
                }}
              >
                {text}
              </p>
            ) : column.isCompanyColumn ? (
              <div>
                <input
                  onChange={(e) => {
                    handleCheck(e, record);
                  }}
                  type="checkbox"
                  className="company-checkbox"
                />
                <Link to={`/company/${record.Ticker}`} className="ms-2">
                  {text}
                </Link>
              </div>
            ) : column.dataType === "string" ? (
              text
            ) : column.isTotalColumn ? (
              <b>
                {(toDollar(text?.[filter.currentYear]?.total) || "–") +
                  (dataHasDiscrepancy(record, filter.currentYear) ? "**" : "")}
              </b>
            ) : column.isCompanyData ? (
              formatMeasure(text, column.dataType) || text === 0 ? (
                (formatMeasure(text, column.dataType) || "0") +
                (column?.isPayRatio ? ":1" : "")
              ) : (
                formatMeasure(text, column.dataType) || "–"
              )
            ) : (
              (column.isRank
                ? text?.[filter.currentYear]?.[column?.compensationIndex]
                : toDollar(
                    text?.[filter.currentYear]?.[column?.compensationIndex]
                  )) || "–"
            )}
          </div>
        );
      },
    }));
  }, [
    companies,
    filter,
    handleCheck,
    allowExecutiveTableExport,
    currentColumns,
    hideColumns,
    PDFprocessing,
  ]);

  const [columns, setColumns] = useState(allExecutivesRef);
  const columnsRef = useRef();
  columnsRef.current = allExecutivesRef.current;

  //TODO: Put this logic in the table filter, no reason to do this every time
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  useEffect(() => {
    if (!delay && !update) {
      if (filter.indexSelected === "All") {
        const companiesTickers = Object.keys(companies);
        setFilteredCompanies(companiesTickers);
      } else {
        setFilteredCompanies(filter.index); //?.[filter.currentYear].Tickers);
      }
    }
  }, [delay, update, filter.indexSelected, filter.index, companies]);

  const [filteredMarketCap, setFilteredMarketCap] = useState(new Map([]));

  useEffect(() => {
    const filteredMarketCapTemp = marketCap.filter((mCap) =>
      filteredCompanies.includes(mCap?.Ticker)
    );

    setFilteredMarketCap(
      new Map(filteredMarketCapTemp.map((mCap, index) => [mCap.Ticker, index]))
    );
    const newCsuiteData = csuiteData.filter((cs) =>
      filteredCompanies.includes(cs?.Ticker)
    );
    setYearFilteredData(newCsuiteData);
  }, [filteredCompanies, marketCap, csuiteData]);

  useMemo(() => {
    if (!delay && !update) {
      const newData = new dataFilter(
        yearFilteredData,
        filter.currentYear,
        companies
      )
        .filterCSuite(csuite)
        .salaryRange(filter.salary.salaryMin, filter.salary.salaryMax)
        .executiveRankRange(
          filter.executiveRank.executiveRankMin,
          filter.executiveRank.executiveRankMax
        )
        .payRatioRange(filter.payRatio.payRatioMin, filter.payRatio.payRatioMax)
        .filterIndustry(filter.industry)
        .filterSector(filter.sector)
        .filterCompany(filter.company)
        .filterCompanyLocation(filter.companyState)
        .filterExecutive(filter.executives)
        .filterExecutiveState(filter.executiveState)
        .filterCompanyCity(filter.companyCity)
        .filterExecutiveCity(filter.executiveCity)
        .percentileCompensation(
          filter.percentile.percentileMin,
          filter.percentile.percentileMax
        )
        .marketCapRange(
          filter.marketCap.marketCapMin,
          filter.marketCap.marketCapMax
        )
        .employeeRange(
          filter.employees.employeesMin,
          filter.employees.employeesMax
        )
        .netIncomeRange(
          filter.netIncome.netIncomeMin,
          filter.netIncome.netIncomeMax
        )
        .revenueRange(filter.revenue.revenueMin, filter.revenue.revenueMax)
        .assetsRange(filter.assets.assetsMin, filter.assets.assetsMax)
        .companyRankRange(
          filter.companyRank.companyRankMin,
          filter.companyRank.companyRankMax,
          filteredMarketCap
        )
        .filterIndexes(filter.index)
        .filterFounders(filter.founders)
        .calculatedDiffAllowed(filter.diffPercentAllowed);

      setData([...newData.data]);
      // console.log("data", newData.data);
      // setUpdate(false);
    }
  }, [delay, update, companies]);

  const [lineData, setLineData] = useState([]);
  const validYears = () => {
    let year = new Date().getFullYear();
    const yearsArray = [];
    for (year; year >= MIN_YEAR; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  };
  const [years] = useState(validYears());

  useMemo(() => {
    if (!delay && !update) {
      // const years = validYears();
      setLineData({});
      for (let y in years) {
        const newData = new dataFilterLine(csuiteData, years[y], companies)
          .filterCSuite(csuite)
          .salaryRange(filter.salary.salaryMin, filter.salary.salaryMax)
          .executiveRankRange(
            filter.executiveRank.executiveRankMin,
            filter.executiveRank.executiveRankMax
          )
          .payRatioRange(
            filter.payRatio.payRatioMin,
            filter.payRatio.payRatioMax
          )
          .filterIndustry(filter.industry)
          .filterSector(filter.sector)
          .filterCompany(filter.company)
          .filterCompanyLocation(filter.companyState)
          .filterExecutiveState(filter.executiveState)
          .filterCompanyCity(filter.companyCity)
          .filterExecutive(filter.executives)
          .filterExecutiveCity(filter.executiveCity)
          .percentileCompensation(
            filter.percentile.percentileMin,
            filter.percentile.percentileMax
          )
          .marketCapRange(
            filter.marketCap.marketCapMin,
            filter.marketCap.marketCapMax
          )
          .employeeRange(
            filter.employees.employeesMin,
            filter.employees.employeesMax
          )
          .netIncomeRange(
            filter.netIncome.netIncomeMin,
            filter.netIncome.netIncomeMax
          )
          .revenueRange(filter.revenue.revenueMin, filter.revenue.revenueMax)
          .assetsRange(filter.assets.assetsMin, filter.assets.assetsMax)
          .companyRankRange(
            filter.companyRank.companyRankMin,
            filter.companyRank.companyRankMax,
            filteredMarketCap
          )
          .filterIndexes(filter.index)
          .filterFounders(filter.founders)
          .calculatedDiffAllowed(filter.diffPercentAllowed);
        lineData[years[y]] = newData.data;
      }
      setLineData([...lineData]);
      // setUpdate(false);
    }
  }, [delay, update]);

  const [lineGroup, setLineGroup] = useState({});

  useMemo(() => {
    setLineGroup({});
    const years = ["2018", "2019", "2020", "2021"];
    // for (let y in years) {
    years.forEach((year) => {
      const newData = new dataFilter(group, year, companies);
      lineGroup[year] = newData.data;
    });
    setLineGroup(lineGroup);
  }, [group, filter.currentYear, companies]);

  useMemo(() => {
    if (!delay && !update) {
      const years = ["2018", "2019", "2020", "2021"];
      setLineGroup({});
      // for (let y in years) {
      years.forEach((year) => {
        const newData = new dataFilter(group, year, companies)
          .filterCSuite(csuite)
          .salaryRange(filter.salary.salaryMin, filter.salary.salaryMax)
          .executiveRankRange(
            filter.executiveRank.executiveRankMin,
            filter.executiveRank.executiveRankMax
          )
          .payRatioRange(
            filter.payRatio.payRatioMin,
            filter.payRatio.payRatioMax
          )
          .filterIndustry(filter.industry)
          .filterSector(filter.sector)
          .filterCompany(filter.company)
          .filterCompanyLocation(filter.companyState)
          .filterExecutive(filter.executives)
          .filterExecutiveState(filter.executiveState)
          .filterCompanyCity(filter.companyCity)
          .filterExecutiveCity(filter.executiveCity)
          .percentileCompensation(
            filter.percentile.percentileMin,
            filter.percentile.percentileMax
          )
          .marketCapRange(
            filter.marketCap.marketCapMin,
            filter.marketCap.marketCapMax
          )
          .employeeRange(
            filter.employees.employeesMin,
            filter.employees.employeesMax
          )
          .netIncomeRange(
            filter.netIncome.netIncomeMin,
            filter.netIncome.netIncomeMax
          )
          .revenueRange(filter.revenue.revenueMin, filter.revenue.revenueMax)
          .assetsRange(filter.assets.assetsMin, filter.assets.assetsMax)
          .companyRankRange(
            filter.companyRank.companyRankMin,
            filter.companyRank.companyRankMax,
            filteredMarketCap
          )
          .filterIndexes(filter.index)
          .filterFounders(filter.founders)
          .calculatedDiffAllowed(filter.diffPercentAllowed);
        lineGroup[year] = newData.data;
      });
      setLineGroup(lineGroup);
    }
  }, [delay, update, companies]);

  const formatPercentile = (percentile) => {
    const p = percentile.toString();
    switch (p[p.length - 1]) {
      case "1":
        return `${percentile}st`;
      case "2":
        return `${percentile}nd`;
      case "3":
        return `${percentile}rd`;
      default:
        return `${percentile}th`;
    }
  };

  // Ability to replace c-positions selected unless ctrl is held down
  const [ctrlHeld, setCtrlHeld] = useState(false);
  function downHandler({ key }) {
    if (key === "Control" || key === "Meta") {
      setCtrlHeld(true);
    }
  }
  function upHandler({ key }) {
    if (key === "Control" || key === "Meta") {
      setCtrlHeld(false);
    }
  }
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
  const newCSuiteArray = (position) => {
    // Start a new array for the positions of interest after clicking on a specific position
    if (
      csuite.includes("allExecutives") ||
      csuite.includes("Board Pay") ||
      position.includes("Board Pay") ||
      !ctrlHeld
    ) {
      if (csuite.length === 1 && position === csuite[0]) {
        return ["allExecutives"];
      }
      return [position];
    }
    // Clicking on an already selected position should remove it from the array
    if (csuite.includes(position)) {
      // ...unless it's the last position in the array, then we go back to allExecutives
      if (csuite.length === 1) {
        return ["allExecutives"];
      }
      return csuite.filter((cs) => cs !== position);
    }
    // Otherwise, we can just add the position to the array
    return [...csuite, position];
  };

  const [pageContainsNewComp, setPageContainsNewComp] = useState(false);
  const [pageContainsDiscrepancies, setPageContainsDiscrepancies] =
    useState(false);
  useEffect(() => {
    setPageContainsNewComp(
      data.some((key) => isFirstYearOfComp(key, filter.currentYear))
    );
    setPageContainsDiscrepancies(
      data.some((key) => dataHasDiscrepancy(key, filter.currentYear))
    );
  }, [data, filter]);

  return (
    <>
      {progressPercent <= 100 ? (
        <div style={{ margin: "auto", marginTop: "-60px", width: "80%" }}>
          <div className="loadingBar" style={{ textAlign: "center" }}>
            {isLoading || loadingBoardPay ? (
              <div
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {progressPercent < 90 ? (
                  <p>
                    Loading data for over{" "}
                    {isLoading
                      ? `${(
                          Math.floor(executivesTotal / 1000) * 1000
                        ).toLocaleString()} 
                    executives...`
                      : `${(
                          Math.floor(boardMembersTotal / 1000) * 1000
                        ).toLocaleString()}
                    board members...`}
                  </p>
                ) : (
                  <p>Just a few more seconds...</p>
                )}
              </div>
            ) : (
              "And... Done!"
            )}
          </div>

          <Progress
            strokeColor={{
              from: "var(--light-blue)",
              to: "var(--primary)",
            }}
            percent={!isLoading && !loadingBoardPay ? 100 : progressPercent}
            status="active"
          />
        </div>
      ) : (
        ""
      )}
      <div
        className="container-fluid"
        style={{
          width: "97.5%",
          maxWidth: "1800px",
          marginTop: progressPercent <= 100 ? "0" : "-50px",
        }}
      >
        <div className="c-table container-fluid mb-5 mt-3">
          <div className="row">
            <div className="table-filters col-lg-2 pe-2">
              <TableFilter
                result={data.length}
                allData={csuiteData}
                data={data}
                setTriggerDelay={setTriggerDelay}
                setTriggerUpdate={setTriggerUpdate}
                csuite={csuite}
                delay={delay}
                update={update}
                progressPercent={progressPercent}
                filteredMarketCap={filteredMarketCap}
                handleResetPagination={handleResetPagination}
                useCalculatedTotals={useCalculatedTotals}
                setUseCalculatedTotals={setUseCalculatedTotals}
                setPDFprocessing={setPDFprocessing}
                setPDFprocessingForAllUsers={setPDFprocessingForAllUsers}
                allowExecutiveTableExport={allowExecutiveTableExport}
                MAX_YEAR={
                  csuite.includes("Board Pay")
                    ? parseInt(DEFAULT_YEAR) + 1
                    : DEFAULT_YEAR
                }
                CORRECTED_MIN_YEAR={
                  csuite.includes("Board Pay")
                    ? parseInt(DEFAULT_YEAR) - 2
                    : MIN_YEAR
                }
              />
            </div>
            <div className="col-md-10 p-0 mt-0">
              <div className="button-group table-selection my-3">
                <div>
                  <button
                    className={csuite.includes("allExecutives") ? "active" : ""}
                    onClick={() => {
                      // don't trigger an update if its already active
                      if (!csuite.includes("allExecutives")) {
                        setColumns(allExecutivesRef.current);
                        setTriggerUpdate(true);
                        dispatch(setCSuite(["allExecutives"]));
                      }
                    }}
                  >
                    All Executives
                  </button>

                  {CpositionsList.filter(
                    (item) => isTier2 || item.title !== "Board Pay"
                  ).map((pos, index) => {
                    // Display positions only if there are more than 20 in database //
                    return pos.count >= 20 ? (
                      <Tooltip
                        overlayInnerStyle={{
                          padding: "10px",
                          color: "var(--secondary)",
                        }}
                        color="white"
                        title={pos.position}
                        placement="bottom"
                        key={index}
                      >
                        <button
                          className={csuite.includes(pos.title) ? "active" : ""}
                          style={{ margin: 5 }}
                          onClick={() => {
                            // setColumns(pos.title.toLowerCase());
                            setTriggerUpdate(true);
                            dispatch(setCSuite(newCSuiteArray(pos.title)));
                          }}
                        >
                          {pos.title}
                        </button>
                      </Tooltip>
                    ) : null;
                  })}
                </div>

                <div
                  className={`${
                    group.length > 1
                      ? "d-flex justify-content-center"
                      : "d-none"
                  } generate-btn-group`}
                >
                  <button
                    onClick={() => {
                      dispatch(
                        showModal(
                          <b>
                            Summary Stats for Selected Executives (
                            {group.length} currently selected)
                          </b>,
                          <div className="c-table" style={{}}>
                            <SummaryStatTable
                              data={group}
                              delay={delay}
                              update={update}
                              csuite={csuite}
                              currentYear={filter.currentYear}
                            />
                            <div className="graphs">
                              <BarGraph
                                data={group}
                                delay={delay}
                                update={update}
                                setTriggerUpdate={setTriggerUpdate}
                                currentYear={filter.currentYear}
                                isBoardPay={csuite.includes("Board Pay")}
                              />
                              <LineGraph
                                data={lineGroup}
                                delay={delay}
                                update={update}
                                currentYear={filter.currentYear}
                                PDFprocessing={PDFprocessingForAllUsers}
                                MAX_YEAR={
                                  csuite.includes("Board Pay")
                                    ? parseInt(DEFAULT_YEAR) + 1
                                    : DEFAULT_YEAR
                                }
                                isBoardPay={csuite.includes("Board Pay")}
                                isHomePage={true}
                              />
                            </div>
                          </div>
                        )
                      );
                    }}
                    className={`btn generate`}
                  >
                    Compare
                  </button>
                  <button
                    onClick={() => {
                      handleResetCheck();
                      setGroup([]);
                    }}
                    className="btn-reset"
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div
                className={`summary-table ${
                  csuite !== "overview" ? "d-block" : "d-none"
                }`}
              >
                <div>
                  <div className="result">
                    {data.length > 0 &&
                    csuite !== "overview" &&
                    !delay &&
                    !update ? (
                      <p>
                        <span>
                          {data.length?.toLocaleString()}{" "}
                          {csuite.includes("allExecutives")
                            ? "Executives"
                            : csuite.join(", ") + "s"}
                        </span>{" "}
                        match the current filters. Below is the percentile data
                        for the{" "}
                        <span>
                          {data.length?.toLocaleString()}{" "}
                          {csuite.includes("allExecutives")
                            ? "Executives"
                            : csuite.join(", ") + "s"}
                        </span>
                        {filter.percentile.percentileMin !== "" &&
                        filter.percentile.percentileMin?.toString() !== "0" &&
                        filter.percentile.percentileMax?.toString() !== "100" &&
                        filter.percentile.percentileMax !== ""
                          ? ` between the ${formatPercentile(
                              filter.percentile.percentileMin
                            )} and ${formatPercentile(
                              filter.percentile.percentileMax
                            )} percentile`
                          : filter.percentile.percentileMin !== "" &&
                            filter.percentile.percentileMin?.toString() !== "0"
                          ? ` above the ${formatPercentile(
                              filter.percentile.percentileMin
                            )} percentile`
                          : filter.percentile.percentileMax !== "" &&
                            filter.percentile.percentileMax?.toString() !==
                              "100"
                          ? ` below the ${formatPercentile(
                              filter.percentile.percentileMax
                            )} percentile`
                          : ""}
                      </p>
                    ) : (
                      <p>
                        {progressPercent < 101 ? (
                          <span> Loading...</span>
                        ) : delay || update ? (
                          <span> Updating...</span>
                        ) : (
                          <span>No results found with current filters.</span>
                        )}
                      </p>
                    )}
                  </div>
                  <div id="table">
                    <SummaryStatTable
                      csuite={csuite}
                      data={data}
                      delay={delay}
                      update={update}
                      currentYear={filter.currentYear}
                    />
                  </div>
                  <div className="graphs" id="graph">
                    <BarGraph
                      data={data}
                      delay={delay}
                      update={update}
                      setTriggerUpdate={setTriggerUpdate}
                      currentYear={filter.currentYear}
                      isBoardPay={csuite.includes("Board Pay")}
                    />
                    <LineGraph
                      data={lineData}
                      delay={delay}
                      update={update}
                      currentYear={filter.currentYear}
                      PDFprocessing={PDFprocessingForAllUsers}
                      MAX_YEAR={
                        csuite.includes("Board Pay")
                          ? parseInt(DEFAULT_YEAR) + 1
                          : DEFAULT_YEAR
                      }
                      isBoardPay={csuite.includes("Board Pay")}
                      isHomePage={true}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="title" style={{ marginTop: "2em" }}>
                <h5 style={{ fontWeight: "bold" }}>
                  Matching{" "}
                  {csuite.includes("allExecutives")
                    ? "Executives"
                    : csuite.join(", ") + "s"}
                  :
                </h5>
              </div>
              <ColumnSelectionModal />
              <div id="executive-table">
                <Table
                  size={"small"}
                  // scroll={{ x: 576 }}
                  columns={columnsRef.current}
                  showSorterTooltip={false}
                  onChange={onChange}
                  loading={isLoading}
                  dataSource={data}
                  bordered
                  pagination={pagination}
                  style={{ minHeight: "600px", marginTop: 5 }}
                  key={index}
                />
                <div>
                  {pageContainsNewComp
                    ? `* ${filter.currentYear} was this executive's first year of compensation.`
                    : ""}
                </div>
                <div>
                  {pageContainsDiscrepancies
                    ? `** C-Suite Comp detects a potential data discrepancy for this officer as the reported sub-components of their pay do not sum to the reported total.`
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
